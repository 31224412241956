// Tạo một Guard để kiểm tra vai trò của người dùng
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from 'src/service/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  // Thêm một trường để lưu trữ quyền cần kiểm tra
  private requiredRole: any;

  constructor(private apiService: ApiService, private router: Router) {}

  // Truyền vào quyền cần kiểm tra qua constructor
  setRequiredRole(role: any) {
    this.requiredRole = role;
  }

  canActivate(): Observable<boolean> {
    return this.apiService.getUser().pipe(
      map((res: any) => {
        const data = res.data.user
        const role = data.role;
        // Kiểm tra nếu vai trò của người dùng khớp với quyền cần kiểm tra thì cho phép truy cập
        if (role == this.requiredRole) {
          return true;
        } else {
          // Nếu không khớp, chuyển hướng đến trang không được phép
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
