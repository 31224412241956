import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { LoginComponent } from './demo/components/auth/login/login.component';
import { AuthGuard } from 'src/service/auth.guard'; // Import AuthGuard từ file guard của bạn

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'login', component: LoginComponent
            },
            {
                path: '', component: AppLayoutComponent, canActivate: [AuthGuard], // Thêm canActivate ở đây
                children: [
                    { path: '', loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'quan-ly', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                ]
            },
            { path: 'auth', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
            { path: '**', redirectTo: '/' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
