import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/service/api.service';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { TranslateService } from 'src/service/translate.service';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [
        `
            ::ng-deep .p-submenu-list {
                width: unset; /* Đặt giá trị width cho ul */
            }
            .layout-topbar-button:hover {
                background-color: transparent;
            }
            .layout-topbar-button:focus {
                outline: none;
                box-shadow: none;
            }
        `,
    ],
})
export class AppTopBarComponent implements OnInit{
    hostName: string;
    subjects: any;
    items!: MenuItem[];
    role: any;
    userName: any;
    user: any;
    logo: string;
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    
    constructor(
        public layoutService: LayoutService,
        private apiService: ApiService,
        private cookie: CookieService,
        private translate: TranslateService
    ) {
        this.hostName = window.location.hostname;
        this.logo = this.getLogoFromHostName(this.hostName);
    }

    ngOnInit(): void {
        this.getTranslate();
    }

    getTranslate(){
        this.translate.getDataTopbar().subscribe((res:any)=>{
            res.forEach(element => {
                this.getDataTopBar(element);
            });
        })
    }

    getDataTopBar(translate: any){
        this.apiService.getUser().subscribe((res: any) => {
            const data = res.data.user

            localStorage.setItem('uid', data.uid);
            // Gán giá trị cho biến role trong hàm subscribe
            this.role = data.role;
            this.userName = `${data.lastName + ' ' + data.firstName}`;
            // Xử lý lo gic dựa trên giá trị của biến role
            if (this.role === 0) {
                this.role = translate.admin;
            } else if (this.role === 1) {
                this.role = translate.merchant;
            }else if (this.role === 2) {
                this.role = translate.staff;
            } else {
                this.role = translate.user;
            }

            // this.items = [
            //     {
            //         title: `Chào, ${this.userName}`,
            //         label: `<span>Chào, ${this.userName}</span><br><span>${this.role}</span>`,
            //         icon: 'pi pi-fw pi-user',
            //         style: {
            //             'text-align': 'center',
            //         },
            //         items: [
            //             {
            //                 badge: `ID: ${data.user.uid}`,
            //                 icon: 'pi pi-key',
            //                 command: () => this.copyuid(data.user.uid),
            //                 style: { width: '200px' },
            //             },
            //             {
            //                 label: 'Thông tin Account',
            //                 icon: 'pi pi-fw pi-user-plus',
            //                 command: () => this.infoAccount(),
            //             },
            //             {
            //                 label: 'Đăng Xuất',
            //                 icon: 'pi pi-fw pi-power-off',
            //                 command: () => this.logout(),
            //             },
            //         ],
            //     },
            // ];
            this.items = [
                {
                    label: `Username: ${data.username}`,
                    icon: 'pi pi-user',
                    command: () => this.copyuid(data.username),
                },
                {
                    label: `Mã KH: ${data.uid}`,
                    icon: 'pi pi-key',
                    command: () => this.copyuid(data.uid),
                },
                {
                    label: 'Thông tin tài khoản',
                    icon: 'pi pi-fw pi-user-plus',
                    command: () => this.infoAccount(),
                },
                { separator: true },
                {
                    label: 'Đăng Xuất',
                    icon: 'pi pi-sign-out',
                    command: () => this.logout(),
                },
            ];
        });
    }

    copyuid(uid: any) {
        console.log(uid);

        // Tạo một textarea tạm thời để chứa nội dung cần sao chép
        const tempTextArea = document.createElement('textarea');
        tempTextArea.style.position = 'fixed'; // Tránh việc hiển thị textarea trên màn hình
        tempTextArea.style.opacity = '0'; // Làm cho textarea không nhìn thấy
        tempTextArea.value = uid; // Đặt nội dung cần sao chép vào textarea
        document.body.appendChild(tempTextArea); // Thêm textarea vào DOM
        tempTextArea.select(); // Chọn nội dung của textarea

        try {
            // Thực hiện sao chép
            const successful = document.execCommand('copy');
            if (successful) {
                Swal.fire({
                    icon: 'success',
                    title: 'Copy thành công',
                    showConfirmButton: false,
                    timer: 1000,
                });
            } else {
                throw new Error('Copy failed');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            Swal.fire({
                icon: 'error',
                title: 'Copy thất bại',
                showConfirmButton: false,
                timer: 1000,
            });
        } finally {
            document.body.removeChild(tempTextArea); // Xóa textarea khỏi DOM sau khi sao chép
        }
    }
    infoAccount() {
        window.location.href = `${environment.sso}/home`;
    }
    logout() {
        this.cookie.deleteAll();
        localStorage.clear()
        this.apiService.logout().subscribe((res) => {
            this.subjects = res;
        });
        window.location.reload();
    }

    getLogoFromHostName(hostname: string): string {
        const parts = hostname.split('.');
        // Lấy phần tử thứ hai từ cuối nếu có subdomain (ví dụ: x.datacenters.vn),
        // hoặc phần tử đầu tiên nếu không có subdomain (ví dụ: datacenters.vn)
        const logo = parts.length > 2 ? parts[parts.length - 2] : parts[0];
        return logo;
    }
}
