import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ApiService } from 'src/service/api.service';
import { Router } from '@angular/router';
import { RoleGuard } from '../demo/service/role.guard';
import { TranslateService } from 'src/service/translate.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    username: string;

    constructor(
        public layoutService: LayoutService, 
        public apiService: ApiService, 
        private roleGuard: RoleGuard,
        private translate: TranslateService
    ) {}

    role: string = 'merchant';

    ngOnInit() {
        this.initializeUserMenu();
    }

    initializeDefaultMenu(translate) {
        this.model.push({
            label: translate.home,
            items: [
                { label: translate.dashboard, icon: 'pi pi-fw pi-home', routerLink: ['/'] }
            ]
        });
    }

    initializeUserMenu() {
        this.apiService.getUser().subscribe((res: any) => {
            const data = res.data.user;
            const role = data.role;
            const username = data.username
            this.roleGuard.setRequiredRole(role);
            this.setupMenuByRole(role, username);
        });
    }

    setupMenuByRole(role: number, username: string) {
        this.translate.getDataSlideBar(role).subscribe((translate: any) => {
            if (role === 0) { // Admin role
                this.initializeDefaultMenu(translate);
                this.addAdminMenu(translate, role, username);
            } else if (role === 1) { // Manager role
                this.initializeDefaultMenu(translate);
                this.addManagerMenu(translate);
            } else if (role === 2) { // Data handler role
                this.initializeDefaultMenu(translate);
                this.addDataHandlerMenu(translate);
            } else { // Other roles
                this.initializeDefaultMenu(translate);
                this.addOtherMenus(translate);
            }
        });
    }

    addAdminMenu(translate, role, username) {
        if (role === 0) {
            // Always add "Quản lý merchant" for role === 0
            this.model.push({
                label: translate.label,
                items: [
                    { label: "Quản lý merchant", icon: 'pi pi-fw pi-table', routerLink: ['/quan-ly/admin-thong-tin-daily'] }
                    // { label: "Lịch sử merchant", icon: 'pi pi-fw pi-table', routerLink: ['/quan-ly/admin-lich-su-merchant'] }
                ]
            });
            // Add additional admin-specific item if username is 'admin'
            if (username === 'admin') {
                this.model[this.model.length - 1].items.unshift({
                    label: translate.item, 
                    icon: 'pi pi-fw pi-table', 
                    routerLink: ['/quan-ly/admin-them-goi']
                });
            }
        }
    }

    addManagerMenu(translate) {
        this.model.push({
            label: translate.label,
            items: [
                { label: translate.item, icon: 'pi pi-fw pi-table', routerLink: ['/quan-ly/quan-ly-goi-cuoc'] },
                { label: translate.item1, icon: 'pi pi-users', routerLink: ['/quan-ly/quan-ly-khach-hang'] },
                { label: translate.item2, icon: 'pi pi-users', routerLink: ['/quan-ly/quan-ly-data-khach'] }
                // { label: translate.item3, icon: 'pi pi-users', routerLink: ['/quan-ly/quan-ly-url'] }
            ]
        });
    }

    addDataHandlerMenu(translate) {
        this.model.push({
            label: translate.label,
            items: [
                { label: translate.item, icon: 'pi pi-minus-circle', routerLink: ['/quan-ly/data-khach-hang'] },
                { label: translate.item1, icon: 'pi pi-check-circle', routerLink: ['/quan-ly/data-khach-hang-success'] }
            ]
        });
    }

    addOtherMenus(translate) {
        this.model.push({
            label: translate.label,
            items: [
                {
                    label: translate.item,
                    icon: 'pi pi-sitemap',
                    routerLink: ['/quan-ly/quan-ly-link-web']
                },
                {
                    label: translate.item1,
                    icon: 'pi pi-globe',
                    routerLink: ['/quan-ly/quan-ly-data-web']
                },
                // {
                //     label: 'Quản lý data khác',
                //     icon: 'pi pi-hashtag',
                //     routerLink: ['/quan-ly/quan-ly-data-khac']
                // }
            ]
        });

        this.model.push({
            label: 'Fbx',
            items: [
                {
                    label: "Facebook Reaction/Comment",
                    icon: 'pi pi-globe',
                    routerLink: ['/quan-ly/list-fb-scan']
                },
                {
                    label: "Facebook Group",
                    icon: 'pi pi-globe',
                    routerLink: ['/quan-ly/list-fb-group']
                },
                {
                    label: "Facebook History",
                    icon: 'pi pi-globe',
                    routerLink: ['/quan-ly/list-fb-history']
                },
            ]
        })
        //Quản lý nhân viên START
        this.model.push({
            label: translate.label1,
            items: [
                {
                    label: translate.item2,
                    icon: 'pi pi-users',
                    routerLink: ['/quan-ly/quan-ly-nhan-vien']
                }
            ]
        });
        //Quản lý nhân viên END
        // this.model.push({
        //     label: 'Quản lý data',
        //     items: [
        //         {
        //             label: 'Quản lý data website',
        //             icon: 'pi pi-globe',
        //             routerLink: ['/quan-ly/quan-ly-data-web']
        //         },
        //         {
        //             label: 'Quản lý data khác',
        //             icon: 'pi pi-hashtag',
        //             routerLink: ['/quan-ly/quan-ly-data-khac']
        //         }
        //     ]
        // });
    }
}
