import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService, private apiService: ApiService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.apiService.getUser().pipe(
      map((data: any) => {        
        // Nếu dữ liệu đã được tải thành công từ API, kiểm tra xác thực
        if (data) {
          // Nếu xác thực thành công, cho phép truy cập
          return true;
        } else {
          // Nếu không xác thực, chuyển hướng đến trang đăng nhập
          this.router.navigate(['/login']);
          return false;
        }
      }),
      catchError((error) => {
        console.log(error);

        // Xử lý lỗi, chuyển hướng đến trang đăng nhập
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}
