import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    localStorageTranslate: any;

    constructor(private http: HttpClient, private apiService: ApiService) {
        this.localStorageTranslate = localStorage.getItem('translate') ? localStorage.getItem('translate') : 'vi' ;
    }

    getDataTopbar() {
        return this.http.get<any>('../assets/demo/data/topbar.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getDataSlideBar(role: number){
        return this.http.get<any>('../assets/demo/data/slidebar.json').pipe(
            map(res => res[this.localStorageTranslate][role])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getMerchantManagerPackage() {
        return this.http.get<any>('../assets/demo/data/merchant-manager-package.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }
    
    getMerchantManagerCustomer() {
        return this.http.get<any>('../assets/demo/data/merchant-manager-customer.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getMerchantManagerDataCustomer() {
        return this.http.get<any>('../assets/demo/data/merchant-manager-data-customer.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getMerchantManagerUrl() {
        return this.http.get<any>('../assets/demo/data/merchant-manager-url.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    //USER TRANSLATE
    getUserManagerLinkWeb() {
        return this.http.get<any>('../assets/demo/data/user-manager-linkweb.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getUserManagerDataLinkWeb() {
        return this.http.get<any>('../assets/demo/data/user-manager-data-website.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getUserManagerStaff() {
        return this.http.get<any>('../assets/demo/data/user-manager-staff.json').pipe(
            map(res => res[this.localStorageTranslate])  // Truy cập động dựa vào giá trị của translate
        );
    }

    getLeadDashboard() {
        return this.apiService.getUser().pipe(
            switchMap((res: any) => {
                const role = res.data.user.role;
                if (role === 1) {
                    // Đối với merchant
                    return this.http.get<any>('../assets/demo/data/dashboard-role.json').pipe(
                        map((res) => res[this.localStorageTranslate].merchant)
                    );
                } else {
                    // Đối với user
                    return this.http.get<any>('../assets/demo/data/dashboard-role.json').pipe(
                        map((res) => res[this.localStorageTranslate].user)
                    );
                }
            })
        );
    }
}