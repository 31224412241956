
export const environment = {
    production: false,
    sso: 'https://myaccount.datacenters.vn',
    crm: 'http://localhost:4201',
    apiUrl: 'https://accounts.datacenters.vn',
    //CRM
    apiUrlPlan: 'https://plan.datacenters.vn',
    apiUrlCrm: 'https://url.datacenters.vn',
    overview: 'https://overview.datacenters.vn',

    fbx: 'https://fb.datacenters.vn'
  };
// export const environment = {
//     production: false,
//     sso: 'http://localhost:4200',
//     crm: 'http://localhost:4201',
//     apiUrl: 'http://localhost:3000',
//     //CRM
//     apiUrlPlan: 'http://localhost:5000',
//     apiUrlCrm: 'http://localhost:7000',
//     overview: 'http://localhost:9000',
//     fbx: 'https://fb.datacenters.vn'
// };

