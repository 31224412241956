import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
// Import services
import { CustomerService } from './demo/service/customer.service';

import { ToastModule } from 'primeng/toast';
@NgModule({
    declarations: [AppComponent],
    imports: [AppRoutingModule, AppLayoutModule, ToastModule],
    providers: [
        // Use PathLocationStrategy for routing
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        // Provide services
        CustomerService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
