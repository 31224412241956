import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
const api = environment.apiUrl;
const apiPlan = environment.apiUrlPlan;
const apiUrl = environment.apiUrlCrm;
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private _refreshNeeded$ = new Subject<void>();

    constructor(private http: HttpClient) {}

    get refreshNeeded() {
        return this._refreshNeeded$;
    }

    newAccUser(body: any): any {
        return this.http.post<any>(`${api}/auth/support-register`, body, {
            withCredentials: true,
        });
    }
    getUser(): Observable<any> {
        return this.http.get<any>(`${api}/user`, {
            withCredentials: true,
        });
    }
    logout(): any {
        return this.http.get<any>(`${api}/auth/log-out`, {
            withCredentials: true,
        });
    }
    //CRM API

    //================START POST PLAN==========================//
    getAllPlan(): Observable<any> {
        return this.http.get<any>(`${apiPlan}/plans`, {
            withCredentials: true,
        });
    }

    getPlan(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${apiPlan}/plans-of-user`, {
            params: queryParams,
            withCredentials: true,
        });
    }

    getPlanByMerchant(): Observable<any> {
        return this.http.get<any>(`${apiPlan}/merchant/plans`, {
            withCredentials: true,
        });
    }

    getPlanCategories(): Observable<any> {
        return this.http.get<any>(`${apiPlan}/plan-categories`, {
            withCredentials: true,
        });
    }


    addPlan(body: any): any {
        return this.http
            .put<any>(`${apiPlan}/plan-of-user`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    updatePlanUrl(body: any): any {
        return this.http
            .put<any>(`${apiPlan}/plan-of-user/add-url`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    updatePlanDay(body: any): any {
        return this.http
            .put<any>(`${apiPlan}/plan-of-user/add-expDays`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    updatePlanBalance(body: any): any {
        return this.http
            .put<any>(`${apiPlan}/plan-of-user/add-balance`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    addUserPlan(body: any): any {
        return this.http
            .post<any>(`${apiPlan}/plan-of-user/add-user`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    addNewPlanCategory(body: any): any {
        return this.http
            .post<any>(`${apiPlan}/plan-category`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    addNewPlan(body: any): any {
        return this.http
            .post<any>(`${apiPlan}/plan`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    updatePlan(body: any): any {
        return this.http
            .put<any>(`${apiPlan}/plan`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }
    //================END PUT PLAN==========================//

    //================START POST URL==========================//
    addUrl(body: any): any {
        return this.http
            .post<any>(`${apiUrl}/url`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    getUrlByUserid(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${apiUrl}/get-all`, {
            params: queryParams,
            withCredentials: true,
        });
    }

    getCustomerByUserid(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${apiUrl}/customers-by-user`, {
            params: queryParams,
            withCredentials: true,
        });
    }


    getUrlCustomerByMerchant(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${apiUrl}/customers-by-merchant`, {
            params: queryParams,
            withCredentials: true,
        });
    }


    updateUrlByOrigin(body: any): any {
        return this.http
            .put<any>(`${apiUrl}/url`, body, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                })
            );
    }

    deleteUrlByOrigin(origin: string): Observable<any> {
        return this.http.put<any>(`${apiUrl}/delete/${encodeURIComponent(origin)}`, null, {
            withCredentials: true,
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }
    //================END POST URL==========================//

    supportResetPassword(userId: string): Observable<any> {
        const url = `${api}/auth/support-reset-password/${userId}`;
        return this.http.get(url, {
            withCredentials: true
        }); // Sử dụng phương thức GET
    }


    //=========================ADMIN============================//
    updateMerchantAdmin(body: any): Observable<any> { //Thêm điểm
        return this.http.put<any>(`${apiPlan}/plan-of-merchant/top-up`, body, {
            withCredentials: true,
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }

    deleteMarkMerchantAdmin(body: any): Observable<any> {
        return this.http.put<any>(`${apiPlan}/plan-of-merchant/decrease-point`, body, {
            withCredentials: true
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }

    historyMerchantByAdmin(body: any){
        return this.http.get(`${apiPlan}/merchant-points-history?merchantId=${body}`, {
            withCredentials: true
        })
    }

    getMerchantByAdmin(): Observable<any> {
        return this.http.get<any>(`${apiPlan}/plan-of-merchant/get-all`, {
            withCredentials: true,
        });
    }

    createMerchantAdmin(body: any): Observable<any> {
        return this.http.post<any>(`${apiPlan}/plan-of-merchant`, body, {
            withCredentials: true,
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }

    getAllMerchant(): Observable<any> {
        return this.http.get<any>(`${apiPlan}/plan-of-merchant`, {
            withCredentials: true,
        });
    }

    //==============API Staff=======================//
    createAccStaff(body: any): Observable<any> {
        return this.http.post<any>(`${api}/auth/staff/register`, body, {
            withCredentials: true,
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }

    putLeadStaff(body: any): Observable<any> {
        return this.http.put<any>(`${apiUrl}/customer/update-process`, body, {
            withCredentials: true,
        }).pipe(
            tap(() => {
                this._refreshNeeded$.next();
            })
        );
    }

    getCustomerStaff(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${apiUrl}/customers-by-staff`, {
            params: queryParams,
            withCredentials: true,
        });
    }

    getStaffByUser(params: any): Observable<any> {
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.set(key, params[key]);
            }
        }
        return this.http.get<any>(`${api}/staffs`, {
            params: queryParams,
            withCredentials: true,
        });
    }
}
