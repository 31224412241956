import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer, Package } from '../api/customer';
import { ApiService } from 'src/service/api.service';
import { map, switchMap } from 'rxjs';

@Injectable()
export class CustomerService {
    constructor(private http: HttpClient, private apiService: ApiService) {}
}
