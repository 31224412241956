<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
        <span style="text-transform: capitalize;">{{logo}}</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button [style]="{'width':'unset'}" class="p-link layout-topbar-button" (click)="menu.toggle($event)"
            pTooltip="Thông tin tài khoản" tooltipPosition="left">
            <i class="pi pi-user"></i>
            <p class="mx-3 text-center" style="font-size: 0.9rem;">{{role}} <br> <b style="font-size: 1.2rem; text-transform: capitalize;">{{userName}}</b><br>{{user}}</p>
        </button>

        <!-- <div class="mx-3">
            <p-menubar [model]="items" [style]="{'border': 'none', 'background': 'none'}"></p-menubar>
        </div> -->
        <!-- <button class="p-link layout-topbar-button" (click)="logout()" pTooltip="Đăng xuất" tooltipPosition="bottom">

        </button> -->
        <!-- <button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button> -->
    </div>
</div>
<p-menu #menu [model]="items" [popup]="true" [style]="{'width' : 'unset'}"></p-menu>
