import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/service/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: [`
        :host ::ng-deep .pi-eye,
        :host ::ng-deep .pi-eye-slash {
            transform:scale(1.6);
            margin-right: 1rem;
            color: var(--primary-color) !important;
        }
    `]
})
export class LoginComponent implements OnInit {
    hostname: any;
    initialized: boolean = false; // Biến để đánh dấu đã khởi tạo hay chưa
    crm : any;
    constructor(private router: Router, private apiService: ApiService){
        
    }

    ngOnInit(): void {
        this.saveLocalhost();
        if (this.initialized) { // Kiểm tra nếu chưa khởi tạo
            this.handleLogin(this.crm);
        }
    }

    saveLocalhost(): void {
        this.hostname = window.location.origin; // Sử dụng window.location.origin thay vì window.location.hostname
        localStorage.setItem('hostname', this.hostname);
        this.initialized = true;
        this.crm = localStorage.getItem('hostname')
    }

    handleLogin(crm: any): void {
        const SSO = environment.sso;
        try {
            this.apiService.getUser().subscribe((data: any) => {
                console.log(data);
                if (data) {
                    window.location.href = `${crm}/`;
                } else {
                    window.location.href = `${SSO}/login?continue=${crm}`;
                } 
            }, (error) => {
                window.location.href = `${SSO}/login?continue=${crm}`;
            });
        } catch (error) {
            window.location.href = `${SSO}/login?continue=${crm}`;
        }
    }
}
