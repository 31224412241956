<button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()">
    <i class="pi pi-spin pi-cog"></i>
</button>

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-20rem">
    <h5>Scale</h5>
    <div class="flex align-items-center">
        <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
        <div class="flex gap-2 align-items-center">
            <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === scale}"></i>
        </div>
        <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
    </div>

    <ng-container *ngIf="!minimal">
        <h5>Menu Type</h5>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
            <label for="mode1">Static</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
            <label for="mode2">Overlay</label>
        </div>
    </ng-container>

    <ng-container *ngIf="!minimal">
        <h5>Input Style</h5>
        <div class="flex">
            <div class="field-radiobutton flex-1">
                <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="inputStyle" inputId="outlined_input"></p-radioButton>
                <label for="outlined_input">Outlined</label>
            </div>
            <div class="field-radiobutton flex-1">
                <p-radioButton name="inputStyle" value="filled" [(ngModel)]="inputStyle" inputId="filled_input"></p-radioButton>
                <label for="filled_input">Filled</label>
            </div>
        </div>

        <h5>Ripple Effect</h5>
        <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch>
    </ng-container>


    <h5>Theme 1</h5>
    <div class="grid">
        <div class="col-3">
            <button class="p-link w-2rem h-2rem" (click)="changeTheme('md-dark-indigo', 'dark')">
                <img src="assets/layout/images/themes/md-dark-indigo.svg" class="w-2rem h-2rem" alt="Material Dark Indigo">
            </button>
        </div>
    </div>

    <h5>Mặc định</h5>
    <div class="grid">
        <div class="col-3">
            <button class="p-link w-2rem h-2rem" (click)="changeTheme('lara-light-indigo', 'light')">
                <img src="assets/layout/images/themes/lara-light-indigo.png" class="w-2rem h-2rem" alt="Lara Light Indigo">
            </button>
        </div>
    </div>

    <h5>Ngôn Ngữ</h5>
    <button class="me-4" style="background-color: transparent; border: none;" (click)="changeLanguage()">
        <img src="{{ currentLanguage == 'vi' ? '../../../../../assets/package/vietnam32.png' : '../../../../../assets/package/my32.png' }}" alt="Language Flag">
    </button>
 
</p-sidebar>
