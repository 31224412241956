import { Component, ElementRef } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { ApiService } from 'src/service/api.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef, private apiService: ApiService) {
        this.apiService.getUser().subscribe((res: any) =>{
            const role = res.role
        })
    }
}

